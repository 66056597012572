.image-overlay-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.image-with-overlay {
  display: block;
}

.overlay-text {
  width: 100%;
  height: 100%;
  text-align: center;
  align-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #4D4D4D;
  font-size: 24px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 5px 10px;
  border-radius: 5px;
}