.ant-radio .ant-radio-inner {
  width: 20px;
  height: 20px;
  background-color: #d9d9d9;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.15);
}

.ant-input-outlined {
  background-color: #d9d9d9;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.15);
}

.ant-picker-outlined {
  background-color: #d9d9d9;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.15);
}

.tab-select-form-self .ant-select-single .ant-select-selector {
  border-radius: 6px 6px 0px 0px;
}

.tab-select-form-friend .ant-select-single .ant-select-selector {
  border-radius: 6px 6px 0px 0px;
}

.tab-select-form-group .ant-select-single .ant-select-selector {
  border-radius: 6px 6px 0px 0px;
}

.tab-select-race .ant-select-single .ant-select-selector {
  border-radius: 6px 6px 0px 0px;
}

.tab-select-form-self .ant-select-selector {
  border: none !important;
  background-color: #150e6e !important;
  color: #ffffff;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.15);
}

.tab-select-form-friend .ant-select-selector {
  border: none !important;
  background-color: #ff9190 !important;
  color: #ffffff;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.15);
}

.tab-select-form-group .ant-select-selector {
  border: none !important;
  background-color: #2db2ad !important;
  color: #ffffff;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.15);
}

.tab-select-race .ant-select-selector {
  border: none !important;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.15);
}

.tab-select-form-self .ant-select-arrow {
  color: #ffffff;
}

.tab-select-form-friend .ant-select-arrow {
  color: #ffffff;
}

.tab-select-form-group .ant-select-arrow {
  color: #ffffff;
}

.title-select .ant-select-selector {
  background-color: #d9d9d9 !important;
  box-shadow: inset 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.15);
}
